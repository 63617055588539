@import "./custom-variables";
@import "./animations";

.notification-section {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
  z-index:201;
  position: fixed;
}

.overlay-section {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
  z-index:101;
  position: fixed;
}

.notification-container {
  animation: fadeIn 0.3s ease-out 1;
}

.notification-card {
  overflow: auto;
  box-shadow: 0.25em 0.5em 0.75em 0.125em rgba(#000, 0.3);
}

.card-container {
  z-index: 102;
  opacity: 0;
  transform: translateY(80px);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.card-container > .card {
  z-index: 102;
  overflow: hidden;
}

.card-container .card .card-content {
  padding-top: 0.5rem;
}

.card-header-title {
  padding-right: 0px;
}

.card-container .card .card-content {
  max-height: calc(100vh - 172px);
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .card-container .card {
    min-width: auto;
  }
}

.error-divider {
  margin: 2px;
}

.columns.is-flex {
  height: 100%;
}